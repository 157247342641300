import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`List`}</h1>
    <p>{`The List component groups related content,
organized vertically.`}</p>
    <ComponentPreview componentName="list--unordered" hasReact hasAngular hasHTML titleAttr="List Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <ul>
      <li parentName="ul">{`Use ordered lists when the order of
the list items is meaningful, i.e.
they are sequential.`}</li>
      <li parentName="ul">{`Use unordered lists when the order
of the list items does not matter.`}</li>
    </ul>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`A List must use the default list style
type (e.g. a bullet or number). Do not
substitute the default with alternative
symbols like Emoji.`}</li>
      <li parentName="ul">{`A list should have at least 2 List Items.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Default`}</h3>
    <p>{`Default List can be ordered (with leading numbers)
or unordered (with leading bullets).`}</p>
    <ComponentPreview componentName="list--ordered" hasReact hasAngular hasHTML titleAttr="Ordered List Example" mdxType="ComponentPreview" />
    <ComponentPreview componentName="list--unordered" hasReact hasAngular hasHTML titleAttr="Unordered List Example" mdxType="ComponentPreview" />
    <h3>{`Bare`}</h3>
    <p>{`Bare List can be ordered or unordered,
but the leading symbol will not be visible
and will not be read by screen readers.`}</p>
    <ComponentPreview componentName="list--bare" hasReact hasAngular hasHTML titleAttr="Bare List Example" mdxType="ComponentPreview" />
    <h3>{`Indented`}</h3>
    <p>{`Indented List should be used when the
list is between blocks of text.`}</p>
    <ComponentPreview componentName="list--indented" hasReact hasAngular hasHTML titleAttr="Indented List Example" mdxType="ComponentPreview" />
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Unless you are using the Bare variant,
screen readers will read “bullet” before
each List Item in an unordered list or
the number for an ordered list.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      